import { i18n } from "~/plugins/i18n";
import { useAppStateStore } from '~/stores/appState'
import { useWishStore } from '~/stores/wish'
import { usePromptpayStore } from '~/stores/promptpay'



export const useContentHomeAndUtils = (page: string) => {

  const router = useRouter();

  const appStateStore = useAppStateStore();
  const wishStore = useWishStore();
  const promptpayStore = usePromptpayStore();

  const lang = computed(() => i18n.global.locale.value)
  const isThaiLang = computed(() => lang.value === 'th')

  const contentHome = computed(() => {
    return {
      title: isThaiLang.value ? 'เมนูหลัก' : 'MAIN FEATURE',
      mainList: [
        {
          id: 1,
          name: "Photowish Book",
          description: isThaiLang.value ? [
            'ข้อมูลเกี่ยวกับงานและการจัดส่ง',
            'เทมเพลสคำอวยพรและการแชร์'
          ] : [
            'Event Information and Shipping',
            'Templates and Share'
          ],
          image: "/images/cover/1-1.png",
          icon: 'fa-solid fa-book-heart',
          isClose: false,
          onClick: () => useRouter().push('/v2/wish')
        },
        {
          id: 2,
          name: "Realtime Slides",
          description: isThaiLang.value ? [
            'รูปพรีเวดดิ้ง',
            'ตั้งค่าการเล่นสได์และการแชร์'
          ] : [
            'Prewedding photos',
            'Setting Slide and Share'
          ],
          image: "/images/cover/2-1.png",
          icon: 'fa-solid fa-presentation-screen',
          isClose: true,
          onClick: () => useRouter().push('/v2/realtimeSlides')
        },
        {
          id: 3,
          name: "PromptPay",
          description: isThaiLang.value ? [
            'ข้อมูลพร้อมเพย์และบัญชีธนาคาร',
            'การแชร์'
          ] : [
            'Promptpay and Bank information',
            'Share'
          ],
          image: "/images/cover/3-1.png",
          icon: "/images/promptpay/promptpay-logo.png",
          isClose: false,
          onClick: () => useRouter().push('/v2/promptpay')
        },
      ],

      title2: isThaiLang.value ? 'เมนูเสริม' : 'Second Feature',
      secondList: [
        {
          id: 'notify',
          name: isThaiLang.value ? "การแจ้งเตือน" : "Notify",
          icon: "fa-brands fa-line",
          onClick: () => {
            console.log('click: notify',)
            useRouter().push('/v2/lineNotify/main')
          }
        },
        {
          id: 'user',
          name: isThaiLang.value ? "เพิ่มบัญชี" : "Users",
          icon: "fa fa-user-plus",
          onClick: () => {
            console.log('click: users',)
            useRouter().push('/v2/users/main')
          }
        },
        // {
        //   id: 'shareLinkAndQrcode',
        //   name: isThaiLang.value ? "การแชร์ Link / Qr Code" : "Share Link / Qr Code ",
        //   icon: "fa-regular fa-qrcode",
        //   isClose: true,
        //   onClick: () => {
        //     console.log('click: share',)
        //     useRouter().push('/share')
        //     appStateStore.setMenuSelected("Share");
        //   }
        // },
        {
          id: 'setting',
          name: isThaiLang.value ? "ตั้งค่า" : "Setting",
          icon: "fa fa-cog",
          isClose: true,
          onClick: () => {
            console.log('click: setting',)
            useRouter().push('/v2/setting/main')
          }
        },
        {
          id: 'faqs',
          name: isThaiLang.value ? "คำถามที่พบบ่อย / FAQs" : "FAQs",
          icon: "fa-solid fa-question-circle",
          isClose: true,
          onClick: () => {
            console.log('click: faqs',)
            useRouter().push('/faq')
            appStateStore.setMenuSelected("Faqs");
          }
        },
        {
          id: 'contact',
          name: isThaiLang.value ? 'ติดต่อเจ้าหน้าที่' : 'Contact Us',
          icon: "fa-solid fa-phone",
          isClose: true,
          onClick: () => {
            console.log('click: contact',)
            useRouter().push('/v2/contact')
            appStateStore.setMenuSelected("Contact");
          }
        },
      ]
    }
  })

  const contentNav = computed(() => {    
    return {
      title: "PhotoWish",
      description: isThaiLang.value ? "เขียนคำอวยพรด้วยรูป" : "Make a wish with your smile",
      list: [
        {
          title: isThaiLang.value ? "หน้าหลัก" : "Home",
          // link: "/main",
          link: "/v2/home",
          icon: "fa-solid fa-home ",
          active: false,
          subPath: [
            "/v2/home",
            "/v2/wish",
            "/v2/promptpay",
            "/v2/realtimeSlide",
            "/v2/contact",
            "/v2/lineNotify",
            "/v2/users",
          ],
        },
        {
          title: isThaiLang.value ? "คำอวยพร" : "My Wishes",
          link: "/wish/wishes",
          active: false,
          icon: "fa-regular fa-square-heart",
          amount: wishStore?.wishedList?.length || 0,
          subPath: ["/wish/wishes"],
        },
        {
          title: isThaiLang.value ? "พร้อมเพย์" : "PromptPay",
          link: "/promptpay",
          active: false,
          icon: "fa-solid fa-envelope-open-dollar",
          amount: promptpayStore?.promptpay?.length || 0,
          subPath: ["/promptpay"],
        },

      ]
    }
  })

  const contentLineNotify = computed(() => {
    return {
      title: "Notification",
      remark: isThaiLang.value ? "หมายเหตุ: การแจ้งเตือนจะถูกส่งผ่านไลน์ @photowish " : "",
      steps: [
        {
          id: '1',
          title: isThaiLang.value ? "1. เป็นเพื่อนกับ @photowish" : "1. Add Line @photowish"
        },
        {
          id: '2',
          title: isThaiLang.value ? "2. คลิกเพื่อเปิดการแจ้งเตือน" : "1. Toggle Switch to enable notifications."
        },
      ] 
    }
  })

  const contentUserInvite = computed(() => {
    return {
      title: isThaiLang.value ? "เพิ่มบัญชี" : "Add User",
      list: [
        {
          id: "user-share-invite",
          title: isThaiLang.value ? "ส่งคำเชิญเข้าร่วม Event" : "Invitational",
          description: isThaiLang.value ? "เลือก Mode และกด Copy ส่งให้เจ้าบ่าว หรือเจ้าสาว เพื่อเข้าถึง Event นี้" :  "Select `role` and click `Copy Link` button and sent to bride or groom for access this event",
          label:isThaiLang.value ? "แชร์" : "Share",
          type:"link",
        },
      ]
    }
  })

  const contentContactUs = computed(() => {
    return {
      title: isThaiLang.value ? "ช่องทางการติดต่อ" : "Contact Us",
      list: [
        {
          question: isThaiLang.value ? "เบอร์ติดต่อ" : "Phone Number",
          answer:
            "063-048-4510",
          open: true,
        },
        {
          question: "Website",
          answer: "www.photowish.com",
          open: true,
        },
        {
          question: "Email",
          answer: "info@photowish.com",
          open: true,
        },
        {
          question: "Facebook",
          answer: "www.facebook.com/photowish.th",
          open: true,
        },
        {
          question: "Line",
          answer: "@photowish",
          open: true,
        },
      ]
    }
  })

  const contentSetting = computed(() => {
    return {
      title: isThaiLang.value ? "การตั้งค่า" : "Setting",
      lang: {
        title: isThaiLang.value ? "ภาษา" : "Language",
      }
    }
  })



  // ---- computed to get current content by page -----
  const currentContent = computed(() => {
    let content: any = {}
    switch (page) {
      case 'home':
        content = contentHome.value
        break;

      case 'nav':
        content = contentNav.value
        break;

      case 'line-notify':
        content = contentLineNotify.value
        break;

      case 'user-invite':
        content = contentUserInvite.value
        break;

      case 'contact':
        content = contentContactUs.value
        break;

      case 'setting':
        content = contentSetting.value
        break;

      default:
        break;
    }

    return content
  })

  // --- For switching languages ----
  const setLang = (targetLang: string) => {
    window.localStorage.setItem('lang', targetLang)
    i18n.global.locale.value = targetLang
  }

  return {
    lang,
    content: currentContent, // The localized content
    setLang,
  }
}
